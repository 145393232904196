import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://exam.budwk.com/api/', // 设置你的后端 API 地址
  timeout: 10000, // 设置请求超时时间
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiClient;
