<template>
  <el-row style="padding-left: 20px;padding-right: 20px;">
        <el-row style="width: 50%;margin-bottom: 10px;" :gutter="20">
          <el-col :span="8">
            
          <el-select v-model="query" placeholder="请选择类型">
            <el-option label="全部" value=""></el-option>
            <el-option label="收藏" value="mark"></el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
            
            <el-select v-model="type" placeholder="请选择类型">
              <el-option label="全部" value=""></el-option>
              <el-option label="单选题" value="单选题"></el-option>
              <el-option label="判断题" value="判断题"></el-option>
              <el-option label="多选题" value="多选题"></el-option>
            </el-select>
          </el-col>
        <el-col :span="8">
          
        <el-button type="primary" @click="list">查询</el-button>
        </el-col>
        </el-row>
        <el-card v-if="tableData.length==0" style="width: 100%;margin-bottom: 20px;">
          暂无数据
        </el-card>
        <el-card v-for="q in tableData" :key="'q_'+q.id" style="width: 100%;margin-bottom: 20px;">
          <template #header>
            <el-tag type="success">{{q.type}}</el-tag> {{ q.id -1 }}、<span>{{q.title}}</span> 
            <el-button v-if="q.mark==0" type="info" @click="mark(q.id, 1)">收藏</el-button> <el-button v-else type="danger" @click="mark(q.id, 0)">取消收藏</el-button>
          </template>
          <el-radio-group v-if="q.type=='单选题'" v-model="q.mask" style="display: grid" @change="checkRadio(q)">
                  <el-radio value="A">A、{{q.A}}</el-radio>
                  <el-radio value="B">B、{{q.B}}</el-radio>
                  <el-radio value="C">C、{{q.C}}</el-radio>
                  <el-radio value="D">D、{{q.D}}</el-radio>
           </el-radio-group>
           <el-radio-group v-if="q.type=='判断题'" v-model="q.mask" style="display: grid" @change="checkRadio(q)">
                  <el-radio value="A">A、对</el-radio>
                  <el-radio value="B">B、错</el-radio>
           </el-radio-group>
           <el-checkbox-group v-if="q.type=='多选题'" v-model="q.masks" style="display: grid" @change="checkBox(q)">
            <el-checkbox value="A" >A、{{q.A}}</el-checkbox>
            <el-checkbox value="B" >B、{{q.B}}</el-checkbox>
            <el-checkbox value="C" >C、{{q.C}}</el-checkbox>
            <el-checkbox value="D" >D、{{q.D}}</el-checkbox>
            <el-checkbox value="E" >E、{{q.E}}</el-checkbox>
          </el-checkbox-group>
           <el-row>
            <el-tag v-if="q.right==1" type="success" style="margin-top: 10px;" >回答正确</el-tag>
            <el-tag v-if="q.right==2" type="danger" style="margin-top: 10px;" >回答错误</el-tag>
           </el-row>
           <el-button v-if="!q.show" type="info" link style="margin-top: 10px;" @click="q.show=true">答案</el-button>
           <el-tag v-else type="danger" style="margin-top: 10px;" @click="q.show=false">参考答案：{{ q.answer }}</el-tag>
        </el-card>

    <el-pagination style="margin-bottom: 50px;" background layout="prev, pager, next, sizes, jumper" :total="total" v-model:page-size="pageSize" v-model:current-page="pageNo" @change="list" />
  </el-row>
</template>

<script>
import apiClient from "../request";
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      query: '',
      type: '',
      total: 0,
      pageNo: 1,
      pageSize: 5,
      tableData: []
    }
  },
  methods: {
    init() { 
      this.list();
    },
    checkBox(q) {
      if(q.masks.sort().join('')==q.answer) {
        q.right = 1;
      } else {
        q.right = 2;
      }
    },
    checkRadio(q) {
      if(q.mask==q.answer) {
        q.right = 1;
      } else {
        q.right = 2;
      }
    },
    tip(q) {
      if(q.show) {
        return q.tip;
      }
      return '';
    },
    mark(id, mark) {
      apiClient.post('/exam/mark',{id: id, mark: mark}).then((res) => {
         var code = res.data.code;
         if(code==0) {
           this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.list();
         }
      });
    },
    list() {
      apiClient.post('/exam/list',{type: this.type, query: this.query, pageNo:this.pageNo, pageSize:this.pageSize}).then((res) => {
         this.tableData = res.data.data.list;
         this.tableData.forEach((q) => {
           q.show = false;
           q.right = 0;
           q.mask = '';
           q.masks = [];
         });
         this.total = res.data.data.total;
      });
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
